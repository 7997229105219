.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    max-width: 600px;
    background: lightgoldenrodyellow;
    border-radius: 5px;
    padding: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.desc {
    text-align: left;
    font-size: 1.2rem;
    color: #5b5151;
}

.img {
    position: relative;
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
}

.price {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    background: #28ce28;
    padding: 5px 10px;
    color: white;
    font-size: 1.2rem;
}

.more {
    color: #16A085;
    cursor: pointer;
    display: inline;
}

button {
    padding: 10px;
    font-size: 1rem;
    background: darkcyan;
    color: wheat;
    border: none;
    border-radius: 4px;
}

button:hover {
    background: #F4D03F;
    color: darkred;
}
