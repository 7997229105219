.cardsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

.noCards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

.noCards h1 {
    text-decoration: none;
}

.noCards button {
    align-self: center;
    background: #F4D03F;
    color: brown;
}

h1 {
    color: brown;
    width: max-content;
    margin: 0 auto;
    padding: 10px 0;
    border-bottom: 2px solid brown;
}
