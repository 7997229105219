@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Barlow', sans-serif;
    margin: 2rem;
    background-color: #F4D03F;
    background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
    background-repeat: no-repeat;
    min-height: calc(100vh - 4rem);
}

button {
    cursor: pointer;
}

.less  {
    height: 100px;
    overflow: hidden;
}
